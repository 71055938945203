<template>
  <div class="header" dir="rtl">
    <v-navigation-drawer 
    v-model="drawer"
     app clipped class="mobileMenuList">
     <div>
     <v-img class="logo" :src="logo" height="100" width="100"></v-img>
    </div>
     <router-link to="/">
      <p  class="duoPara">DUO</p>
      <p class="travel">Travel  Agency</p>
     </router-link>
      <p class="Tech4SudanMobilePara">ديو للسفر والسياحة  & الحج والعمرة</p>
      <v-list>
        <v-list-item
        class="mobileListItem"
          v-for="item in menu"
          :key="item.link"
          route
          :to="item.route"
        >
          <v-list-item-content class="">
            <v-list-item-title  class="mobileMenuListTitle">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed dense height="80px">
      <v-app-bar-nav-icon
        class="menuIcon hidden-md-and-up"
        @click.stop="drawer = !drawer"
      />
      <v-toolbar-items
        v-for="item in menu"
        :key="item.title"
        class="hidden-sm-and-down"
      >
        <v-btn :to="item.route" class="menuItemsDesktop">
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        href
        onclick="window.open('https://www.facebook.com/profile.php?id=100089230179697')"
        class="mx-4 white--text facebookIcon"
        icon
      >
        <svg style="width: 35px; height: 35px" viewBox="0 0 24 24">
          <path
            fill="#2ec4b6"
            d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
          />
        </svg>
      </v-btn>
      <v-btn class="hotCallsBtn">
        <span class="hotCalls">
          <a style="color: #2ec4b6" href="tel:6591">
            6591
                  <!-- <svg style="width: 40px; height: 40px" viewBox="0 0 24 24">
                    <path
                      fill="#ff9f1c"
                      d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z"
                    />
                  </svg> -->
                </a>
          </span> 
        <v-icon color="#2ec4b6"> fas fa-thin fa-headset</v-icon>
      <!-- <v-icon color="red" left class="mr-2">fas fa-regular fa-user-headset</v-icon> -->
    </v-btn> 
      <v-spacer></v-spacer>
       <router-link to="/">
      <v-toolbar-title  class="duoHome">DUO</v-toolbar-title>
       </router-link>
    </v-app-bar>
  </div>

</template>
<script>

const duoLogo = require("../assets/duoLogo.jpg");

export default {
  name: "DuoHeader",
  components: {
   
  },
  data: () => ({
    logo: duoLogo,
    drawer: false,
    menu: [
      { title: "فلاي ديو", route: "/" },
      { title: "خدمات", route: "/duoServices" },
      { title: "اتصل بنا", route: "/duoCallUs" },
    ],

  }),
  methods: {
   
  }
};
</script>

<style scoped>
.header{
  z-index: 9999999;
}
.logo{
 margin: 0 auto;
 /* width: 50px;
 height: 50px; */
}
.menuItemsDesktop {
  padding: 10px;
  color: #e71d36;
  font-weight: 500;
  font-size: 18px !important;
  margin-left: 1px;
}
.duoHome {
  color: #e71d36;
  font-size: 35px;
  font-weight: 500;
  padding: 8px;
}
.hotCallsBtn{
  border: 1px solid #2ec4b6;
}
.hotCalls{
  color: #2ec4b6;
  padding-left: 4px; 
  font-size: 18px;
  font-weight: 600;
}
.menuIcon {
  color: #e71d36 !important;
}
.mobileListItem {
  border-radius: 5px;
  background: #e71d36;
  margin-top: 1px;
}
.mobileMenuListTitle {
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
}
.duoPara {
  text-align: center;
  color: #060930;
  font-size: 30px;
  font-weight: 500;
  padding: 3px;
}
.travel{
  text-align: center;
  color: #e71d36;
}
.Tech4SudanMobilePara {
  color: #060930;
  text-align: center;
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  .menuIcon {
    margin-right: 7px !important;
  }
}

</style>
