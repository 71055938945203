<template>
  <v-app>
    <DuoHeader />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import DuoHeader from "./components/DuoHeader.vue";


export default {
  name: "App",
    // Components
    components: {
    DuoHeader,
  },
  data: () => ({
    //
  }),
};
</script>
<style>
#app {
  font-family: "mirza";
}
a {
  text-decoration: none;
}
</style>