import { createRouter, createWebHistory } from "vue-router";
import DuoHome from "../views/DuoHome.vue";
import DuoCallUs from "../views/DuoCallUs.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: DuoHome,
  },
  {
    path: "/duoCallUs",
    name: "duoCallUs",
    component: DuoCallUs,
  },
  {
    path: "/duoServices",
    name: "duoAbout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "DuoServices" */ "../views/DuoServices.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
