<template>
  <div class="callus">
    <div class="cardDiv">
        <v-card  class="infoCard">
          <v-card-text>
            <div  class="font-weight-bold  mb-2">
              <v-img class="logo" :src="logo" height="50" width="50"></v-img>
              Duo Travel Agency
              </div>
            <div  class="font-weight-bold  mb-2">ديو للسفر والسياحة  </div>
            <v-timeline align-top dense class="">
              <v-timeline-item
                v-for="message in messages"
                :key="message.color"
                :color="message.color"
                small
              >
                <div>
                  <h3> {{ message.topic }}</h3>
                </div>
                <p  class="message">{{ message.message }}</p>
                <v-divider class="mt-2"></v-divider>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </div>
      </div>
</template>

<script>
const duoLogo = require("../assets/duoLogo.jpg");

export default {
  name: "DuoCallUs",
  data: () => ({
    logo: duoLogo,
    messages: [
      {
        topic: "العنوان",
        color: "#2ec4b6",
        message: "المنشية - شمال تقاطع شارع الستين مع الدوحة",
      },
      {
        topic: "ساعات العمل",
        color: "#2ec4b6",
        message: "السبت - الخميس 8:00 - 23:00",
      },
      {
        topic: "راسلنا علي الايميل",
        color: "#2ec4b6",
        message: "duo@duo-fly.com",
      },
      {
        topic: "اتصال & واتس",
        color: "#2ec4b6",
        message: "+249123160337",
      },
      {
        topic: "اتصال & واتس",
        color: "#2ec4b6",
        message: "+249916181566",
      },
    ],
  })
};
</script>
<style  scoped>
.callus {
  height: 100%;
  background: #2ec4b6;
  text-align: center;
 
}
.cardDiv{
   
   padding-top: 200px;
}
.infoCard {
  margin: 0 auto;
  width: 600px;
}
.message {
  font-weight: 500;
}
@media screen and (max-width: 600px) {
  .cardDiv{
   padding-top: 100px;
   margin-bottom: 50px;
}
  .infoCard {
    width: 310px;
}
}
</style>